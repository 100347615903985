import { defineComponent } from "vue";

import Hero from "@/components/hero/index.vue";
import SummitLink from "@/components/summit-link/index.vue";
import heroBg from "../../assets/images/about-us-bg.png";

export default defineComponent({
  name: "Procedures for Approvals",
  components: { SummitLink, Hero },
  setup() {
    const heroSlide = [
      {
        title: "Procedures for Approvals",
        image: heroBg,
      },
    ];

    return {
      heroSlide,
    };
  },
  data() {
    return {

      // filesrcss: [
      //   { title: 'Data for CofO', src: 'http://127.0.0.1:8000/downloads/Data-for-CofO' },
      //   { title: '2022 Nasarawa State Debt Sustainability Analysis DSA-DMS Report', src: 'http://127.0.0.1:8000/downloads/The-2022-Nasarawa-State-Debt-Sustainability-Analysis-DSA-DMS-Report' },
      //   { title: 'NASARAWA STATE FEES AND LEVIES', src: 'http://127.0.0.1:8000/downloads/NASARAWA-STATE-FEES-AND-LEVIES' },
      //   { title: 'Nasarawa State Investment Incentive Inventory', src: 'http://127.0.0.1:8000/downloads/Nasarawa-State-Investment-Incentive-Inventory' },
      //   { title: 'PROCEDURE FOR OBTAING BUILDING PERMIT', src: 'http://127.0.0.1:8000/downloads/PROCEDURE-FOR-OBTAING-BUILDING-PERMIT' },
      //   { title: 'PROCEDURE FOR OBTAINING APPROVAL FOR SIGNAGE', src: 'http://127.0.0.1:8000/downloads/PROCEDURE-FOR-OBTAINING-APPROVAL-FOR-SIGNAGE' },
      //   { title: 'Procedure for Obtaining Approval to Connect to Public Water', src: 'http://127.0.0.1:8000/downloads/Procedure-for-Obtaining-Approval-to-Connect-to-Public-Water' },
      //   { title: 'Procedure for Obtaining Business Premises Permit', src: 'http://127.0.0.1:8000/downloads/Procedure-for-Obtaining-Business-Premises-Permit' },
      //   { title: 'Procedure for obtaining CofO', src: 'http://127.0.0.1:8000/downloads/Procedure-for-obtaining-CofO' },
      //   { title: 'Procedure for Obtaining Forest Produce Permit', src: 'http://127.0.0.1:8000/downloads/Procedure-for-Obtaining-Forest-Produce-Permit' },
      //   { title: 'Procedure for obtaining RofO', src: 'http://127.0.0.1:8000/downloads/Procedure-for-obtaining-RofO' },
      //   { title: "Procedure for Obtaining Waste Management Services’ Approval", src: 'http://127.0.0.1:8000/downloads/Procedure-for-Obtaining-Waste-Management-Services-Approval' },
      //   { title: 'PROCEDURE FOR PROPERTY REGISTRATION', src: 'http://127.0.0.1:8000/downloads/PROCEDURE-FOR-PROPERTY-REGISTRATION' },
      //   { title: 'The Procedure of Obtaining Vehicle Inspection Approval', src: 'http://127.0.0.1:8000/downloads/The-Procedure-of-Obtaining-Vehicle-Inspection-Approval' },
        
      // ],


      filesrcs: [
        { title: 'Data for CofO', src: 'https://api.nasida.na.gov.ng/downloads/Data-for-CofO' },
        { title: '2022 Nasarawa State Debt Sustainability Analysis DSA-DMS Report', src: 'https://api.nasida.na.gov.ng/downloads/The-2022-Nasarawa-State-Debt-Sustainability-Analysis-DSA-DMS-Report' },
        { title: 'NASARAWA STATE FEES AND LEVIES', src: 'https://api.nasida.na.gov.ng/downloads/NASARAWA-STATE-FEES-AND-LEVIES' },
        { title: 'Nasarawa State Investment Incentive Inventory', src: 'https://api.nasida.na.gov.ng/downloads/Nasarawa-State-Investment-Incentive-Inventory' },
        { title: 'PROCEDURE FOR OBTAING BUILDING PERMIT', src: 'https://api.nasida.na.gov.ng/downloads/PROCEDURE-FOR-OBTAING-BUILDING-PERMIT' },
        { title: 'PROCEDURE FOR OBTAINING APPROVAL FOR SIGNAGE', src: 'https://api.nasida.na.gov.ng/downloads/PROCEDURE-FOR-OBTAINING-APPROVAL-FOR-SIGNAGE' },
        { title: 'Procedure for Obtaining Approval to Connect to Public Water', src: 'https://api.nasida.na.gov.ng/downloads/Procedure-for-Obtaining-Approval-to-Connect-to-Public-Water' },
        { title: 'Procedure for Obtaining Business Premises Permit', src: 'https://api.nasida.na.gov.ng/downloads/Procedure-for-Obtaining-Business-Premises-Permit' },
        { title: 'Procedure for obtaining CofO', src: 'https://api.nasida.na.gov.ng/downloads/Procedure-for-obtaining-CofO' },
        { title: 'Procedure for Obtaining Forest Produce Permit', src: 'https://api.nasida.na.gov.ng/downloads/Procedure-for-Obtaining-Forest-Produce-Permit' },
        { title: 'Procedure for obtaining RofO', src: 'https://api.nasida.na.gov.ng/downloads/Procedure-for-obtaining-RofO' },
        { title: "Procedure for Obtaining Waste Management Services’ Approval", src: 'https://api.nasida.na.gov.ng/downloads/Procedure-for-Obtaining-Waste-Management-Services-Approval' },
        { title: 'PROCEDURE FOR PROPERTY REGISTRATION', src: 'https://api.nasida.na.gov.ng/downloads/PROCEDURE-FOR-PROPERTY-REGISTRATION' },
        { title: 'The Procedure of Obtaining Vehicle Inspection Approval', src: 'https://api.nasida.na.gov.ng/downloads/The-Procedure-of-Obtaining-Vehicle-Inspection-Approval' },
        
      ],
    }
  }, 
  methods: {
    goto(url) {
      window.open(url);
    },
  },

});
